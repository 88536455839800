import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {

  const navigate = useNavigate()
  let location =  window.location.pathname.toString()

  const handleHire = () => {
    navigate('/contact')
  }

  return (
    <div className='mx-36 mt-auto phone:mx-auto phone:justify-center'>
      {(location !== '/contact') && 
      <div className='flex flex-row phone:flex-col gap-4 justify-between phone:justify-center'>
        <div>
        <button className='text-white font-bold border-2 border-color-white rounded-md my-5 py-1 px-10 text-xl bg-transparent hover:bg-white hover:text-black transition-duration-300 transition ease-out hover:scale-110 hover:drop-shadow-lg' onClick={handleHire}>
          Get website
        </button>
        </div>
        <p className='my-7'>
          smaatta.dev@gmail.com
        </p>
        <div className='flex flex-row gap-4 text-xl self-center'>
          <a href='https://github.com/samuelmdev' target="_blank" rel="noopener noreferrer">{FaGithub()}</a>
          <a href='https://www.linkedin.com/in/samuel-maatta-685864268' target="_blank" rel="noopener noreferrer">{FaLinkedin()}</a>
        </div>
      </div>}
    </div>
  )
}

export default Footer