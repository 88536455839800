import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineMenu } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import MenuItems from './MenuItems';
import { Transition } from '@tailwindui/react'

const Header = () => {

  useEffect(() => {
    let tIndex = buttons.findIndex(e => e.location === window.location.pathname.toString())
    let curIndex = buttons.findIndex(e => e.selected === true)
    if (curIndex !== tIndex) {
      let stateCopy = buttons.slice()
      stateCopy.forEach((item) => item.selected = false)
      stateCopy[tIndex].selected = true
      setButtons(stateCopy)
    }
  })

  const [buttons, setButtons] = useState([
    {
      id:1,
      title: 'Home',
      location: '/',
      selected: true
    },
    {
      id:2,
      title: 'Services',
      location: '/services',
      selected: false
    },
    {
      id:3,
      title: 'Tech',
      location: '/technologies',
      selected: false
    },
    {
      id:4,
      title: 'About',
      location: '/about',
      selected: false
    },
    {
      id:5,
      title: 'Contact',
      location: '/contact',
      selected: false
    }
  ])
  const [isChanged, setIsChanged] = useState(true)

  const getButtons = () => {
    const items = MenuItems()
    setButtons(items)
  }
  
  //const [buttons, setButtons] = useState([])

  const [toggleMenu, setToggleMenu] = useState(false)
  const navigate = useNavigate()

  const buttonSelected = (props) => {
    let stateCopy = buttons.slice()
    stateCopy.map((item, index) => {
      if (index === props.index) {
       navigate(`${props.location}`)
       console.log('if suoritettu')
      } else {item.selected = false
        console.log('else suoritettu')}
    })
    setButtons(stateCopy)
    setIsChanged(!isChanged)
  }

  const menuToggled = () => {
    setToggleMenu(!toggleMenu)
  }

  const changeMenuIcon = () => {
    return(
      (toggleMenu) ? FaTimes() : AiOutlineMenu()
    )
  }

  const menuItems = () => {
    return (
      <div className='laptop:my-3 desktop:my-3'>
        <div className='phone:flex phone:flex-col flex flex-row'>
          {buttons.map((name, index) => (
            (name.selected) ?
            <p className=' bg-opacity-80 border-b-2 border-white text-xl font-medium px-4 py-2 phone:py-4 text-black bg-white transition-duration-300 transition ease-in-out'>
              {name.title}
            </p> :<button 
              key={name.id} 
              onClick={() => {buttonSelected({index, location: name.location})}}
              className='bg-black bg-opacity-20 border-b-2 border-white text-white text-xl font-medium px-4 py-2 phone:py-4 hover:text-black hover:bg-white transition-duration-300 transition ease-in-out hover:drop-shadow-lg'
            >
              {name.title}
            </button> 
          ))}
        </div>
      </div>)
  }

  return (
      <div className='flex flex-row justify-around my-6 mx-16'>
        <div className='flex flex-row'>
          {buttons.map((name, index) => (
            (name.selected) ?
            <div
            className=' border-r-2 border-white rounded-2xl text-2xl font-medium px-8 py-5 mr-48 laptop:mr-24 text-black bg-white transition-duration-300 transition ease-in-out drop-shadow-lg hover:cursor-default'
          >
            {name.title}
          </div>
         : null
          ))}
          <div className='flex laptop:hidden desktop:hidden my-4'>
            <button className='text-2xl text-white hover:bg-white hover:text-black px-1 py-1' onClick={menuToggled}>{changeMenuIcon()}</button>
          </div>
          </div>
        {menuItems()}
        {console.log(window.location.pathname)}
      </div>
  )
  }

  export default Header
