import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineMenu } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const MobileHeader = () => {

  useEffect(() => {
    let tIndex = buttons.findIndex(e => e.location === window.location.pathname.toString())
    let curIndex = buttons.findIndex(e => e.selected === true)
    if (curIndex !== tIndex) {
      let stateCopy = buttons.slice()
      stateCopy.forEach((item) => item.selected = false)
      stateCopy[tIndex].selected = true
      setButtons(stateCopy)
    }
  })

  const [buttons, setButtons] = useState([
    {
      id:1,
      title: 'Home',
      location: '/',
      selected: true
    },
    {
      id:2,
      title: 'Services',
      location: '/services',
      selected: false
    },
    {
      id:3,
      title: 'Tech',
      location: '/technologies',
      selected: false
    },
    {
      id:4,
      title: 'About',
      location: '/about',
      selected: false
    },
    {
      id:5,
      title: 'Contact',
      location: '/contact',
      selected: false
    },
  ])

  const navigate = useNavigate()

  const buttonSelected = (props) => {
    let stateCopy = buttons.slice()
    stateCopy.map((item, index) => {
      if (index === props.index) {
       navigate(`${props.location}`)
       setToggleMenu(false)
       console.log('if suoritettu')
      } else {item.selected = false
        console.log('else suoritettu')}
    })
    setButtons(stateCopy)
  }

  const [toggleMenu, setToggleMenu] = useState(false)

  const menuToggled = () => {
    setToggleMenu(!toggleMenu)
  }

  const changeMenuIcon = () => {
    return(
      (toggleMenu) ? FaTimes() : AiOutlineMenu()
    )
  }

  const menuItems = () => {
    return (
    <div className='flex flex-col absolute mt-20 self-center rounded-2xl'>
    {buttons.map((name, index) => (
      (!name.selected) ? 
      <button 
        key={name.id} 
        onClick={() => {buttonSelected({index, location: name.location})}}
        className='bg-black bg-opacity-80 border-white text-white text-xl font-medium py-4 px-14 hover:text-black hover:bg-white transition-duration-300 transition ease-in-out hover:drop-shadow-lg'
      >
        {name.title}
      </button> : null
      ))}
    </div>
    )
  }

  return (
    <div className='flex flex-col align-items-center self-center my-6 mx-2'>
      <div className='flex flex-row self-center'>
        {buttons.map((name, index) => (
            (name.selected) ? <div
            className=' border-r-2 border-white rounded-2xl text-2xl font-medium px-8 py-4 mr-4 text-black bg-white transition-duration-300 transition ease-in-out drop-shadow-lg hover:cursor-default'
          >
            {name.title}
          </div> : null
          ))}
        <div className='flex my-4'>
          {(!toggleMenu) ? <button className='text-2xl text-white px-1 py-1 hover:drop-shadow-lg' onClick={menuToggled}>{changeMenuIcon()}</button> :
          <button className='text-2xl text-white px-1 py-1 hover:drop-shadow-lg' onClick={menuToggled}>{changeMenuIcon()}</button>}
        </div>
        </div>
      {(toggleMenu) && menuItems()}
      {console.log(window.location.pathname)}
    </div>
)
}

export default MobileHeader