import React, { useState, useEffect } from 'react'
import Header from './Header';
import MobileHeader from './MobileHeader';

const MenuSetter = () => {

  useEffect(() => {
    changeMenu();
  }, []);

  const [menuChange, setMenuChange] = useState(false)
  
  const changeMenu = () => {
    if (window.innerWidth <= 767) {
      setMenuChange(true);
    } else {
      setMenuChange(false);
    }
  };

  window.addEventListener('resize', changeMenu);

  return (
    <div>
      {(menuChange) ? <MobileHeader /> : <Header />}
    </div>
  )
}

export default MenuSetter