import React, { useState } from 'react'
import HireLink from './HireLink';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { FaReact, FaHtml5 } from "react-icons/fa";
import { SiJavascript, SiCss3, SiTailwindcss } from "react-icons/si";
import TechCard from './TechCard';
import { useNavigate } from 'react-router-dom'

export const Technologies = () => {

  const [swiper, setSwiper] = useState(null);
  const [showTech, setShowTech] = useState(false);
  const [tIndex, setTIndex] = useState(null)

  const navigate = useNavigate()

  const showTechCard = (props) => {
    console.log('onClick kutsuttu')
    console.log('slide index:', props.index)
    setShowTech(true)
    setTIndex(props.index)
    navigate(<TechCard />)
  }

  return (
    <div className='mx-36 my-36 phone:mx-6 phone:my-6'>
      <div className='flex flex-col mb-10 content-center'>
        <h1 className='text-white text-5xl my-1 mb-10'>Technologies I use</h1>
        <p className='text-white text-lg'>I develop websites using React. React produces responsive and easily modified websites.</p>
        <p className='text-white text-lg'>Other technologies I use with React are JavaScript, Html, CSS and Tailwind.</p>
      </div>
      <div className='justify-content-center my-10 mb-16 text-xl font-semibold bg-slate-200 hover:bg-white text-black rounded-lg hover:drop-shadow-xl'>
        <Swiper
        onSwiper={setSwiper}
        spaceBetween={15}
        slidesPerView={3}
        grabCursor={true}        
        >
          <SwiperSlide onClick={() => {showTechCard({index:0})}} className='py-10 px-6 pl-10'>
            <FaReact className='scale-150 text-sky-500'/>React
          </SwiperSlide>
          <SwiperSlide onClick={() => {showTechCard({index:1})}} className='py-10 px-6'>
            <SiJavascript className='scale-150 bg-black text-yellow-400'/>JavaScript
          </SwiperSlide>
          <SwiperSlide onClick={() => {showTechCard({index:2})}} className='py-10 px-6'>
            <FaHtml5 className='scale-150 text-red-600'/>HTML
          </SwiperSlide>
          <SwiperSlide onClick={() => {showTechCard({index:3})}} className='py-10 px-6'>
            <SiCss3 className='scale-150 text-blue-600'/>CSS
          </SwiperSlide>
          <SwiperSlide onClick={() => {showTechCard({index:4})}} className='py-10 px-6'>
            <SiTailwindcss className='scale-150 text-cyan-600'/>Tailwind CSS
          </SwiperSlide>
        </Swiper>
      </div>
      <div>
        {(showTech) && <TechCard index={tIndex}/>}
      </div>
      <div className='phone:flex phone:flex-col phone:self-center'>
        <div className='phone:flex phone:self-center'><p className='text-white text-xl font-bold'>Contact for more info about my tech-stack</p></div>
        <div className='phone:flex phone:self-center'><HireLink /></div>
      </div>
    </div>
  )
}
