import React from 'react'
import HireLink from './HireLink'

const Services = () => {
  return (
    <div className='mx-36 my-36 phone:mx-6 phone:my-6'>
      <div className='flex flex-col mb-10 content-center'>
        <h1 className='text-white text-5xl my-1 mb-10'>My Services</h1>
        <p className='text-white text-lg'>I can design and develop your website from a scratch</p>
        <p className='text-white text-lg'>I can also add functionality for your existing project and do bug fixing</p>
      </div>
      <div className='mb-10'>
        <h2 className='text-white text-3xl my-1 mb-6'>Pricing</h2>
        <p className='text-white text-lg'>We can agree on a contract price for your project or I can develop for hourly rate</p>
        <p className='text-white text-xl font-bold'>Present your project or idea and contact for prices</p>
      </div>
      <div className='phone:flex phone:flex-col phone:self-center'>
        <HireLink />
      </div>
    </div>
  )
}

export default Services