import React from 'react';
import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import { Contact } from './components/Contact';
import Services from './components/Services';
import { Introduction } from './components/Introduction';
import { Technologies } from './components/Technologies';
import About from './components/About';
import Footer from './components/Footer';
import MenuSetter from './components/MenuSetter';

// TODO
// Menu hover
// Contact form change for phones
// Revision text

function App() {
  return (
    <div className='bg-gradient-to-t from-black to-slate-800 py-2 text-white flex flex-col phone:self-center justify-between min-h-screen'>
      <Router>
        <MenuSetter />
        <div className='flex flex-col content-end'>
            <Routes>
              <Route exact path='/' element={<Introduction />}/>
              <Route path='/services' element={<Services />}/>
              <Route path='/technologies' element={<Technologies/>}/>
              <Route path='/about' element={<About/>}/>
              <Route path='/contact' element={<Contact/>}/>
            </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  )
}

export default App;
