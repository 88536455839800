import React, { useState, useRef } from 'react'
import { FaGithub, FaLinkedin } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import Header from './Header';

export const Contact = () => {
  const form = useRef();

  const [name, setName] = useState("")
  const [email, setEMail] = useState("")
  const [header, setHeader] = useState("")
  const [message, setMessage] = useState("")
  const [showLine, setShowLine] = useState(false)
  const [line, setLine] = useState("")

/*  const validate = (values) => {
    const errors = {}
  
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
  
    return errors
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  }) */

  const sendEmail = (e) => {

    console.log('senEmail e:', e)

    emailjs.sendForm('service_u7jtecl', 'template_jyvilnx', form.current, 'kUXXzjjdgvlTYUL7V')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const handleSubmit = (event) => {
    //validateEmail(email)
    if (name.length < 5 || email.length < 8 || header.length < 5 || message.length < 11) {
      showAck()
      setLine("Check inputs")
    } else { 
      event.preventDefault();
      sendEmail(event)
      setLine ("Message sent. Thanks for contacting!")
      showAck()
      setName("")
      setEMail("")
      setHeader("")
      setMessage("")
    // alert(`The info you entered was: ${name}`)
    }
  }

  const validateEmail = (mail) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail.value)) {
      console.log(mail)
    return (true)
    }
    console.log(mail)
    alert("You have entered an invalid email address!")
    return (false)
  }

  const showAck = () => setShowLine(true)
  const hideAck = () => setShowLine(false)
  const lineField = (string) => {
    let line = string
    return (line)}

  return (
    <div className='mt-36 mx-36 phone:mt-6 phone:mx-6 flex flex-col'>
    <div className='gap-20 flex flex-row phone:flex-col phone:gap-10'>
      <div className='phone:self-center'>
        <h1 className='text-white text-5xl my-1 mb-10 phone:mb-2'>Contact</h1>
      </div>
      <div className='self-center'>
        <form className='flex flex-col gap-4 text-md my-4' ref={form} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            name="from_name"
            onChange={(e) => setName(e.target.value)}
            onFocus={hideAck}
            className='text-black px-2 py-1 mx-2 outline-none border-white w-2/3 phone:w-full'
          />
          <input
            type="text" 
            placeholder="Email"
            value={email}
            name="email"
            onChange={(e) => setEMail(e.target.value)}
            onFocus={hideAck}
            //onBlur={formik.handleBlur}
            className='text-black px-2 py-1 mx-2 outline-none border-white w-2/3 phone:w-full'
          />
          <input
            type="text"
            placeholder="Subject"
            value={header}
            name="subject"
            onChange={(e) => setHeader(e.target.value)}
            onFocus={hideAck}
            className='text-black px-2 py-1 mx-2 outline-none border-white w-2/3 phone:w-full'
          />
          <textarea
            type="text"
            placeholder="Message"
            value={message}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            onFocus={hideAck}
            className='text-black px-2 py-1 mx-2 outline-none border-white h-20 phone:w-full w-96'
          />
        </form>
        <div className='flex flex-col items-start'>
          <button className='text-white font-bold border-2 border-color-white rounded-lg my-4 mx-3 py-2 px-16 text-xl bg-transparent hover:bg-white hover:text-black transition-duration-300 transition ease-out hover:scale-110 hover:drop-shadow-xl' onClick={handleSubmit}>Send</button>
          {(showLine) && <p className='mx-3'>{line}</p>}
          {/*formik.touched.email && formik.errors.email && (
              <span>{formik.errors.email}</span>
          )*/}
        </div>
        </div>
      </div>
    </div>
  )
}
