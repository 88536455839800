import React from 'react'
import HireLink from './HireLink'

const About = () => {
  return (
    <div className='mx-36 my-36 phone:mx-6 phone:my-6 phone:flex phone:flex-col phone:self-center'>
      <h1 className='text-white text-5xl my-1 mb-10'>About me</h1>
      <p className='text-white text-lg w-5/6'>I am a freelance web developer and familiar with React development. I am constantly learning new and upgrading my tech-stack. I have soon studied IT for three years at Oulu University of Applied Sciences.</p>
      <p className='text-white text-lg w-5/6 mb-2'>Currently I live in capital region of Finland and I'm finishing my studies remotely.</p>
      <p className='text-white text-lg w-5/6 mb-4'>While I'm located in Finland, I do deliver my work anywhere there is an internet connection.</p>
      <div className='phone:flex phone:self-center'>
        <HireLink />
      </div>
    </div>
  )
}

export default About