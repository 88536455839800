import React from 'react'
import { useNavigate } from 'react-router-dom'

const HireLink = () => {

  const navigate = useNavigate()
  let location =  window.location.pathname.toString()

  const handleHire = () => {
    navigate('/contact')
  }

  const selecText = () => {
    if (location === '/services') return ("Contact for pricing")
    if (location === '/technologies') return ("Ask more")
    if (location === '/about') return ("Contact me")
    else return ("Hire")
  }

  return (
    <button className='text-white font-bold border-2 border-color-white rounded-md my-4 py-2 px-16 text-xl bg-transparent hover:bg-white hover:text-black transition-duration-300 transition ease-out hover:scale-110 hover:drop-shadow-xl' onClick={handleHire}>
      {selecText()}
    </button>
  )
}

export default HireLink