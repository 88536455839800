import HireLink from './HireLink';

export const Introduction = () => {

  return (
    <div className='flex flex-col justify-between phone:items-center mx-36 my-36 phone:mx-6 phone:my-6 phone:mb-20'>
      <div className='flex flex-col items-start desktop:ml-12'>
        <h1 className='text-white text-7xl'>
          Samuel M.
        </h1>
        <span className='text-slate-400 text-lg italic font-light'>
          Front-end Web Developer
        </span>
        <span className='text-white text-lg my-4'>
          I design and develop responsive websites 
        </span>
        <div className='flex phone:self-center'>
          <HireLink />
        </div>
      </div>
    </div>
  )
}
