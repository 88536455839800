import React, { useEffect, useState } from 'react'
import TechStackArray from './TechStackArray'

const TechCard = (props) => {

  useEffect(() => {
    const techs = TechStackArray()
    setTechArray(techs)
    console.log('techcard array on: ', techArray)
  }, [])

  const [techArray, setTechArray] = useState()

  const techDesc = () => {
    return(
    <div className='flex flex-col gap-2 w-5/6 my-16'>
      <h2 className='text-xl font-semibold'>{techArray[props.index].tech}</h2>
      <p className='text-lg'>{techArray[props.index].desc}</p>
    </div>
    )
  }

  return (
    <div>
      {(techArray) && techDesc()}
      {console.log('Techcard props', props.index)}
    </div>
  )
}

export default TechCard